import { createSlice } from '@reduxjs/toolkit'

const useInfo = createSlice({
  // 模块名称独一无二
  name: 'useInfo',
  // 初始数据
  initialState: {
    info: {}
  },
  // 修改数据的同步方法
  reducers: {
    SaveInfo (state, action) {


      // state.info = action.payload
      return {
        ...state, // 保持其他状态属性不变
        info: { ...action.payload } // 确保 info 是新的对象
      };
    }
  }
})

const { SaveInfo } = useInfo.actions
const reducer = useInfo.reducer

// 导出修改数据的函数
export { SaveInfo }
// 导出reducer
export default reducer